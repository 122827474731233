import { createApp } from 'vue'
import App from './App.vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/bootstrap-icons.css'

import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue';

// import Vue from 'vue';
// import Button from 'ant-design-vue/lib/button';
// import 'bootstrap-vue/dist/bootstrap-vue.css'


// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
// Vue.component(Button.name, Button);
// Vue.config.productionTip = false;

const app = createApp(App);
// .mount('#app')
app.config.productionTip = false;

app.use(Antd);
app.mount('#app');
