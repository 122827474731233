<template>
    <div id="base">
        <!-- <h1>{{ msg }}</h1> -->
        <div id="captcha-div">
            <div id="select-coordinate" v-for="point, index in selectedCoordinates" :key="index">
                <i 
                id="selected-coordinate-point" 
                class="bi  bi-circle-fill" 
                :style="{
                    position: 'fixed', 
                    top: point.y - 15 + 'px',
                    left: point.x - 10 + 'px'
                }">
                </i>
            </div>
            <img id="captcha" :src="captcha" @click="clickCaptcha($event)">
        </div>

        <!-- <input type="text" class="form-control" v-model="verificationData" @input="handleInputCaptcha()"> -->
        <!-- <button type="button" class="btn btn-outline-primary" @click="refresh()">Refresh</button> -->
        <!-- <button type="button" class="btn btn-outline-primary" @click="verify()">Verify</button> -->

        <p>Click successively: {{ text }}</p>
        <!-- <p>验证结果：<a :style="{color: this.verificationStatus ? '#198754' : '#d30d0d'}">{{ result }}</a></p> -->
    </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
// import axios from "axios";

export default {
    props: [
        "data"
    ],
    data() {
        return {
            captcha: null,
            text: null,

            // 已选坐标
            selectedCoordinates: [],
            // 用户输入的验证码
            verificationData: [],
        }
    },
    mounted() {
        this.captcha = this.data.captcha;
        this.text = this.data.text;
    },
    watch: {
        data(data){
            this.captcha = data.captcha;
            this.text = data.text;
        }
    },
    methods: {
        // refresh () {
        //     axios.get("http://deploy.local:8852/authentication/getCaptcha")
        //     .then(response => {
        //         this.captcha = response.data.result.captcha;
        //         this.type = response.data.result.type;
        //         this.uuid = response.data.result.uuid;
        //         this.text = response.data.result.text;
        //         // this.status = response.data.status;
        //     })
        // },
        // verify () {
        //     axios({
        //         method: "post",
        //         url: "http://deploy.local:8852/authentication/verifyCaptcha",
        //         headers: {'Content-Type': 'application/json; charset=utf-8'},
        //         data: JSON.stringify({
        //             uuid: this.uuid,
        //             verify: this.verificationData
        //         })
        //     }).then(response => {
        //         this.result = response.data.msg;
        //         this.verificationStatus = response.data.status;
        //     })
        // },
        // handleInputCaptcha(){
        //     if (this.verificationData.length >= 4){
        //         this.verify();
        //         this.refresh();
        //         this.verificationData = "";
        //     }
        // },
        verify(){
            // 将用户输入数据抛到上层处理
            this.$emit("verificationData", this.verificationData);
            // 数据重置
            this.verificationData = [];
            this.selectedCoordinates = [];
        },
        clickCaptcha(event){
            // 取坐标
            let x = event.offsetX;
            let y = event.offsetY;
            this.verificationData.push([x, y]);
            // 显示坐标图像
            this.selectedCoordinates.push({
                "x": event.pageX,
                "y": event.pageY
            })
            // 进行验证
            if (this.verificationData.length >= this.text.length){
                this.verify();  
            }
        }
    },

}

</script>

<style scoped>

#base {
    margin: 0.5em;
}

p {
    text-align: center;
    margin: 0.5em;
}

#captcha-div {
    text-align: center;
}

#captcha {
    width: 320px;
    height: 240px;
}

/* 已选择点 */
#selected-coordinate-point {
    color: #E83015;
    font-size: 20px;
}

</style>