<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <Login />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import StandardCaptcha from './components/StandardCaptcha.vue'
// import ClickCaptcha from './components/ClickCaptcha.vue'
// import SpinCaptcha from './components/SpinCaptcha.vue'
// import Captcha from './components/captcha/Captcha.vue'
import Login from './components/Login.vue'

export default {
  name: 'App',
  components: {
    Login
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
