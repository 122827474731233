<template>
    <div>
        <div id="captcha-div">
            <div class="d-flex">
                <img id="circle" :src="captcha.circle" :style="{transform: 'rotate(' + verificationData + 'deg)'}">
                <img id="base" :src="captcha.base">
            </div>
        </div>
        
        <div id="info">
            <input @change="verify()" type="range" class="form-range" id="range-input" min="0" max="360" v-model="verificationData">
            <p>Rotate the graph to adjust it to the correct angle</p>
        </div>
        <!-- <p>Angle: {{ verificationData }}</p> -->
        <!-- <p>验证结果：<a :style="{color: this.verificationStatus ? '#198754' : '#d30d0d'}">{{ result }}</a></p> -->
    </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
// import axios from "axios";

export default {
    props: [
        "data"
    ],
    data() {
        return {
            // 验证码底图和圆形
            captcha: {
                circle: null,
                base: null,
            },
            
            // 用户输入的验证码
            verificationData: 0,
        }
    },
    mounted() {
            // this.data = this.dataProps;
            // this.type = this.data.type;
            // this.uuid = this.data.uuid;
            this.captcha.circle = this.data.circle;
            this.captcha.base = this.data.base;
    },
    watch: {
        // 加一个监听器，不然数据不会更新
        data(value) {
            // this.type = value.type;
            // this.uuid = value.uuid;
            this.captcha.circle = value.circle;
            this.captcha.base = value.base;
        }
    },
    methods: {
        verify () {
            // 将用户输入数据抛到上层处理
            this.$emit("verificationData", this.verificationData);
            // 数据重置
            this.verificationData = 0;
        },
    },

}

</script>

<style scoped>
p {
    text-align: center;
}

/* #captcha-div {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
} */

#circle {
    /* position: absolute; */
    width: 100px;
    height: 100px;
    z-index: 2;
    margin: 0 auto
}

#base {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
}

#info {
    padding: 0.5em;
    position: relative;
    /* border: 1px red solid; */
    text-align: center;
}

#range-input {
    max-width: 60%;
}

</style>