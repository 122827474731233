<template>
    <div id="base">
        <!-- <h1>{{ msg }}</h1> -->
        <div class="d-flex">
            <img id="captcha" :src="captcha">
            <input id="input" type="text" class="form-control" v-model="verificationData" @input="handleCaptchaInputChange()">
        </div>
        <!-- <button type="button" class="btn btn-outline-primary" @click="refresh()">Refresh</button> -->
        <!-- <button type="button" class="btn btn-outline-primary" @click="verify()">Verify</button> -->
        <!-- <p>{{ result }}</p> -->
    </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
// import axios from "axios";

export default {
    props: [
        "data"
    ],
    data() {
        return {
            captcha: null,
            // 用户输入的数据
            verificationData: null,
        }
    },
    mounted() {
        this.captcha = this.data.captcha;
    },
    watch: {
        data(data){
            this.captcha = data.captcha;
        }
    },
    methods: {
        verify () {
            // 将用户输入数据抛到上层处理
            this.$emit("verificationData", this.verificationData);
            // 数据重置
            this.verificationData = null;
        },
        handleCaptchaInputChange(){
            if (this.verificationData.length >= 4){
                this.verify();
            }
        }
    },

}

</script>

<style scoped>

#base {
    /* border: 1px red solid; */
    margin: 0.5em;
}

#captcha {
    width: 120px;
    height: 36px;
}

#input {
    margin: 0 0 0 0.5em;
    /* width: 50%; */
    /* text-align: center; */
}
</style>