<template>
    <div>

        <button type="button" class="btn btn-outline-primary" @click="showCaptcha = !showCaptcha">Open Captcha</button>
        <!-- 可以通过 v-if 控制组件销毁 -->
        <a-modal v-model:visible="showCaptcha" v-if="showCaptcha" title="I'm not Robot!" centered>
            <Captcha @verificationResult="handleVerify"/>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">Return</a-button> -->
                <!-- <a-button key="submit" type="primary" :loading="loading" @click="handleOk">Submit</a-button> -->
                <i></i>
            </template>
        </a-modal>

        
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Captcha from "./captcha/Captcha.vue";

export default defineComponent({
    name: "Login",
    components: {
        Captcha,
    },
    setup() {
        
    },
    props: [

    ],
    data() {
        return {
            showCaptcha: false,
            verifiedHash: null,
        }
    },
    mounted() {
        
    },
    watch() {

    },
    methods: {
        // 处理验证结果
        handleVerify(result){
            // 验证成功拿到 verifiedHash 然后关闭对话框
            if (result.status){
                this.verifiedHash = result.verifiedHash;
                this.showCaptcha = false;
            }
        }
    },
})
</script>


<style scoped>

</style>