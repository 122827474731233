<template>
    <div>
        <!-- 验证码 -->
        <div>
            <SpinCaptcha v-if="type === 'SpinCaptcha'" :data="data" @verificationData="verify"/>
            <ClickCaptcha v-if="type === 'ClickCaptcha'" :data="data" @verificationData="verify"/>
            <!-- <StandardCaptcha v-if="type === 'StandardCaptcha'" :data="data" @verificationData="verify"/> -->
            <StandardCaptcha v-if="type === 'StandardCaptcha' || type === 'StandardGifCaptcha'" :data="data" @verificationData="verify"/>
        </div>

        <!-- 验证结果 -->
        <div id="info">
            <p>{{ message? "Verification Result: ":""}}<a :style="{color: this.verificationStatus ? '#198754' : '#d30d0d'}">{{ message }}</a></p>
        </div>
        <!-- <p>Verified Hash: <a>{{ verifiedHash }}</a></p> -->
        
        <!-- 刷新 -->
        <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="refresh()">Refresh</button>
        </div>
    </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import axios from "axios";
import SpinCaptcha  from "../captcha/SpinCaptcha.vue";
import ClickCaptcha  from "../captcha/ClickCaptcha.vue";
import StandardCaptcha  from "../captcha/StandardCaptcha.vue";


export default {
    name: "Captcha",
    components: {
        SpinCaptcha,
        ClickCaptcha,
        StandardCaptcha,
    },
    data() {
        return {
            // 验证码类型
            type: null,
            // 验证码 UUID
            uuid: null,
            // 验证码数据, 每个类别的数据均不同, 交给子组件处理
            data: null,
            // 验证结果
            message: null,
            verificationStatus: false,
            verifiedHash: null,
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        // 刷新验证码
        refresh(){
            // 清空缓存
            // this.type = null;
            // this.uuid = null;
            // this.message = null,
            // this.verificationStatus = false,
            // this.verifiedHash = null,

            axios.get("http://deploy.local:8852/authentication/getCaptcha")
            .then(response => {
                // 验证码类型
                this.type = response.data.result.type;
                // 验证码 UUID
                this.uuid = response.data.result.uuid;
                // 验证码的数据，交给对应的组件处理
                this.data = response.data.result;
            })
        },
        // 验证用户输入
        verify(verificationData){
            axios({
                method: "post",
                url: "http://deploy.local:8852/authentication/verifyCaptcha",
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                data: JSON.stringify({
                    uuid: this.data.uuid,
                    verify: verificationData
                })
            }).then(response => {
                this.verificationStatus = response.data.status;
                this.message = response.data.msg;
                this.verifiedHash = response.data.result.verifiedHash
                // 验证错误, 重新验证
                if (!this.verificationStatus){
                    this.refresh();
                }else {
                    // 验证成功, 将结果抛向上层调用者
                    let result = {
                        status: this.verificationStatus,
                        verifiedHash: this.verifiedHash
                    }
                    this.$emit("verificationResult", result)
                }
            })
        }
    },

}

</script>

<style scoped>

#info {
    text-align: center;
}
</style>